<script>
import { authMethods } from "@/state/helpers";
import { required, email } from "vuelidate/lib/validators";
import { userService } from "@/services/user.service";

export default {
  data() {
    return {
      user: {
        email: "",
        token: "",
        password: "",
      },
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      loading: false,
      resendTokenLoading: false,
      tokenSent: false,
      errors: {},
      showPassword: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  validations: {
    user : {
      email: { required, email },
      token: { required },
      password: { required },
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    async tryToReset() {
      this.submitted = true;
      this.$store.dispatch("notification/clear");
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          try {
            this.errors = {};
            this.loading = true;
            var response = await userService.resetPassword(this.user);

            this.$store.dispatch("notification/success", response.data.message);
            this.loading = false;

            this.$router.push('/login');
          } catch (error) {
            this.loading = false;
            console.log(error);
            switch (error.response.status) {
              case 422:
                this.errors = error.response.data.errors;
                break;
              case 401:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              case 403:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                this.$router.push({
                  name: "verify-email",
                  params: { email: this.user.email },
                });
                break;
              case 500:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              default:
                if (error.response.data.message)
                  this.$store.dispatch(
                    "notification/error",
                    error.response.data.message
                  );
                else
                  this.$store.dispatch(
                    "notification/error",
                    "Something went wrong. Please try again!"
                  );
                break;
            }
          }
      }
    },
    async resendToken() {
      this.errors = {};
      this.submitted = true;

      this.$store.dispatch("notification/clear");
      this.$v.user.email.$touch();

      if (this.$v.user.email.$invalid) {
        return;
      } else {
        try {
          this.resendTokenLoading = true;
          var response = await userService.resendToken(this.user);
          this.tokenSent = true;
          this.resendTokenLoading = false;
          this.$store.dispatch("notification/success", response.data.message);
        } catch (error) {
          this.resendTokenLoading = false;
          console.log(error.response.status);
          console.log(error);
          // console.log(error.response.data.error);
          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$store.dispatch(
                "notification/error",
                error.response.data.message
              );
              break;
            default:
              this.$store.dispatch(
                "notification/error",
                "Something went wrong. Please try again!"
              );
              break;
          }
        }
      }
    },
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-2">Reset Password</h4>
                        <!-- <p class="text-muted">Reset your password</p> -->
                      </div>

                      <div class="p-2 mt-5">
                        <!-- <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                        >{{error}}</b-alert> -->
                        <b-alert
                          :variant="notification.type"
                          class="mt-1"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ notification.message }}</b-alert
                        >

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.email.$error) ||
                                  errors.email
                              }"
                            />
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.email.required">Email is required.</span>
                              <span v-if="!$v.user.email.email">Please enter valid email.</span>
                            </div>
                            <div class="invalid-feedback" v-if="errors">
                              <span v-if="errors.email"
                                >{{ errors.email[0] }} &nbsp;</span
                              >
                            </div>
                          </div>

                          <span v-if="tokenSent">
                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-key-line auti-custom-input-icon"></i>
                              <label for="token">Verification Token</label>
                              <input
                                v-model="user.token"
                                type="number"
                                class="form-control"
                                id="token"
                                placeholder="Enter Token"
                                :class="{
                                  'is-invalid':
                                    (submitted && $v.user.token.$error) ||
                                    errors.token
                                }"
                              />
                              <div v-if="submitted && $v.user.token.$error" class="invalid-feedback">
                                <span v-if="!$v.user.token.required">Token is required.</span>
                              </div>
                              <div class="invalid-feedback" v-if="errors">
                                <span v-if="errors.token"
                                  >{{ errors.token[0] }} &nbsp;</span
                                >
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-1">
                              <i
                                class="ri-lock-2-line auti-custom-input-icon"
                              ></i>
                              <label for="userpassword">Password</label>
                              <input
                                v-model="user.password"
                                :type="passwordFieldType"
                                class="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                :class="{
                                  'is-invalid':
                                    (submitted && $v.user.password.$error) ||
                                    errors.password,
                                }"
                              />
                              <div
                                v-if="
                                  (submitted && !$v.user.password.required) ||
                                  errors.password
                                "
                                class="invalid-feedback"
                              >
                                <span v-if="errors.password">{{
                                  errors.password[0]
                                }}</span>
                                <span v-if="!$v.user.password.required"
                                  >Password is required.</span
                                >
                              </div>
                            </div>
                            <div class="text-right custom-control custom-checkbox text-muted">
                                <input type="checkbox" id="showPassword" class="custom-control-input" v-model="showPassword"/>
                                <label
                                class="custom-control-label"
                                for="showPassword"
                                > Show Password
                                </label>
                            </div>
                            
                          </span>
                          <div class="mt-2 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              v-if="tokenSent"
                            >Reset Password
                              <b-spinner
                                  v-show="loading"
                                  small
                                  class="align-middle"
                              ></b-spinner>
                            </button>
                            <span
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              @click="resendToken()"
                              v-else
                            >Send Verification Token
                              <b-spinner
                                  v-show="resendTokenLoading"
                                  small
                                  class="align-middle"
                              ></b-spinner>
                            </span>
                            <p v-if="tokenSent">
                              <span>Didn't receive the token ? </span>
                              <a
                                href="javascript:void(0)"
                                @click="resendToken()"
                                class="font-weight-medium"
                              >
                                <span>Resend</span>&nbsp;
                                <b-spinner
                                  v-show="resendTokenLoading"
                                  small
                                  class="align-middle"
                                ></b-spinner>
                              </a>
                            </p>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>
                        <p>
                         © {{ currentYear }} Cadlands. 
                         <!-- Powered by
                          <a href="https://encodeup.com" target="blank"
                            >Encodeup</a
                          > -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>